import React, { useState, useEffect, useRef } from 'react';
import { MChart } from '@altair/mchart';
import { Dialog, DialogType, MessageBar, MessageBarType } from '@fluentui/react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as TextMapping from '../../../utils/textMapping';
import * as actions from '../../../actions';
//import * as d3 from '@altair/d3-mchart/d3-mchart.js';

const mapStateToProps = (state) => {
  return {
    roots: state.roots,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(actions, dispatch);
};

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

function CLTEDialog({ hidden, onDismiss, plotData, roots, downloadPDF }) {
  const [chart, setChart] = useState(null);
  const showControlPoints = false;
  const [downloadMsg, setDownloadMsg] = useState(false);

  function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
      function handleResize() {
        /*d3.select('#clte')
          .selectAll('*')
          .remove();*/
        setChart(null);
        setWindowDimensions(getWindowDimensions());
      }

      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chart]);

    return windowDimensions;
  }

  const { height, width } = useWindowDimensions();
  const dialogWidth = width - 100;
  const dialogHeight = height - 100;
  //adjusting svg size considerting legend size and aspect ratio
  const svgWidth = dialogWidth / dialogHeight > 1 ? dialogWidth / Math.sqrt(2) : dialogWidth;
  const svgHeight = dialogWidth / dialogHeight > 1 ? dialogHeight : dialogHeight / Math.sqrt(2);
  const legendConf = {
    position: 'right',
    showcurvepoints: showControlPoints,
    border: {
      enable: true,
      offset: 10,
      paddingx: 4,
    },
    height: {
      min: 50,
    },
  };
  const svgRef = useRef();

  const dialogContentProps = {
    type: DialogType.normal,
    closeButtonAriaLabel: 'Close',
    subText: '',
  };

  function showDownloadMessage() {
    setDownloadMsg(TextMapping.getUIText(TextMapping.UI_TEXT_DOWMLOAD_MESSAGE, roots.texts));
    setTimeout(() => {
      setDownloadMsg(false);
    }, 3500);
  }

  useEffect(() => {
    if (chart === null && svgRef.current) {
      setChart(new MChart('clte', 'clte').get());
    }
  }, [chart, svgRef]);

  useEffect(() => {
    if (chart && plotData) {
      try {
        let icons = { copy: false };

        if (roots.perms && roots.perms.includes('ui.pdf')) {
          icons.resizeout = false;
          icons.download =
            "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' class='icon' height='1em' width='1em'%3E%3Cpath d='M7.503 13.002a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 1 0v-.5H8.5a1.5 1.5 0 0 0 0-3h-.997Zm.997 2h-.497v-1H8.5a.5.5 0 1 1 0 1Zm6.498-1.5a.5.5 0 0 1 .5-.5h1.505a.5.5 0 1 1 0 1h-1.006l-.001 1.002h1.007a.5.5 0 0 1 0 1h-1.007l.002.497a.5.5 0 0 1-1 .002l-.003-.998v-.002l.003-2.002Zm-3.498-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h.498a2 2 0 0 0 0-4H11.5Zm.5 3v-2a1 1 0 0 1 0 2ZM20 20v-1.164c.591-.281 1-.884 1-1.582V12.75c0-.698-.409-1.3-1-1.582v-1.34a2 2 0 0 0-.586-1.414l-5.829-5.828a.491.491 0 0 0-.049-.04a.63.63 0 0 1-.036-.03a2.072 2.072 0 0 0-.219-.18a.652.652 0 0 0-.08-.044l-.048-.024l-.05-.029c-.054-.031-.109-.063-.166-.087a1.977 1.977 0 0 0-.624-.138a.56.56 0 0 1-.059-.007a.605.605 0 0 0-.082-.007H6a2 2 0 0 0-2 2v7.168c-.591.281-1 .884-1 1.582v4.504c0 .698.409 1.3 1 1.582V20a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2Zm-2 .5H6a.5.5 0 0 1-.5-.5v-.996h13V20a.5.5 0 0 1-.5.5Zm.5-10.5v1h-13V4a.5.5 0 0 1 .5-.5h6V8a2 2 0 0 0 2 2h4.5Zm-1.122-1.5H14a.5.5 0 0 1-.5-.5V4.621L17.378 8.5Zm-12.628 4h14.5a.25.25 0 0 1 .25.25v4.504a.25.25 0 0 1-.25.25H4.75a.25.25 0 0 1-.25-.25V12.75a.25.25 0 0 1 .25-.25Z'%3E%3C/path%3E%3C/svg%3E";
          icons.resetzoom =
            "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000' class='icon' height='1em' width='1em'%3E%3Cpath d='m250 850l-187 0-63 0 0-62 0-188 63 0 0 188 187 0 0 62z m688 0l-188 0 0-62 188 0 0-188 62 0 0 188 0 62-62 0z m-875-938l0 188-63 0 0-188 0-62 63 0 187 0 0 62-187 0z m875 188l0-188-188 0 0-62 188 0 62 0 0 62 0 188-62 0z m-125 188l-1 0-93-94-156 156 156 156 92-93 2 0 0 250-250 0 0-2 93-92-156-156-156 156 94 92 0 2-250 0 0-250 0 0 93 93 157-156-157-156-93 94 0 0 0-250 250 0 0 0-94 93 156 157 156-157-93-93 0 0 250 0 0 250z' transform='matrix(1 0 0 -1 0 850)'%3E%3C/path%3E%3C/svg%3E";
        }

        chart.config('tools', icons);
        chart.config('togglecurvepoints', true);
        chart.config('download', true).dispatcher.on('download', () => {
          showDownloadMessage();
          //onDismiss();
          let clteDiv = document.getElementById('clte');
          let clone = clteDiv.cloneNode(true);
          // clone.setAttribute('height', '800px');
          // clone.setAttribute('width', '750px');
          // clone.setAttribute('style', '');

          let materialTitle = '';
          if (roots.materialDatasheet.data && plotData.name) {
            materialTitle = roots.materialDatasheet.data.filter((d) => d.type === 'title')[0];
          }
          let materialId = (materialTitle && materialTitle.data.materialId) || '0';
          downloadPDF(materialId, 'CLTE', roots.appContent, 'diagram', clone.outerHTML);
        });

        if (dialogWidth / dialogHeight < Math.sqrt(2)) {
          legendConf.position = 'bottom';
          chart.config('legend', legendConf);
        } else {
          legendConf.position = 'right';
          chart.config('legend', legendConf);
        }

        chart.config('resetzoom', true);
        chart.draw(plotData);
        chart.resize();

        //hiddenChart.cfg.fullrangemin = isToggled;

        /*        //chart.dispatch.on('togglecurvepoints', onShowControlPoints);
        chart.dispatch.on('download', function() {
          /*
          showDownloadMessage();
          let hiddenDiv = document.getElementById('polarcharthidden');
          hiddenDiv.setAttribute('height', '500px');
          hiddenDiv.setAttribute('width', '600px');
          hiddenChart.config('legend', { width: 200, pos: 'bottom' });
          hiddenChart.cfg.fullrangemin = isToggled;
          hiddenChart.draw(polarData);
          hiddenChart.applyFinalSize(true);
          downloadPDF('0', 'Polar', roots.appContent, 'polarchart', hiddenDiv.outerHTML);
          hiddenDiv.innerHTML = '';

          
        });*/

        //chart.applyFinalSize(true);
      } catch (e) {
        // ignore
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plotData, chart, width, height]);

  function layerDidMount() {
    if (svgRef.current && !chart) {
      setChart(new MChart('clte', 'clte').get());
    }
  }

  function layerWillUnmount() {
    setChart(null);
  }

  return (
    <Dialog
      hidden={hidden}
      onDismiss={onDismiss}
      minWidth={dialogWidth}
      maxWidth={dialogWidth}
      dialogContentProps={dialogContentProps}
      modalProps={{
        layerProps: {
          onLayerDidMount: layerDidMount,
          onLayerWillUnmount: layerWillUnmount,
        },
        isBlocking: true,
      }}
    >
      <div id="clte-container" className="mchart" style={{ margin: 'auto', width: dialogWidth - 24, height: dialogHeight - 24 }}>
        <div style={{ display: 'flex', height: '100%', width: '100%' }}>
          <div id="cltechartvisible" style={{ width: svgWidth, height: svgHeight - 24 }}>
            <svg ref={svgRef} id="clte" className="clte" style={{ overflow: 'visible' }}></svg>
          </div>
        </div>
      </div>
      {/* <div id="parent-container-hidden" style={{ padding: '10px', position: 'absolute', opacity: 0, pointerEvents: 'none' }}>
          <svg id="cltecharthidden" className="cltechart" width={`${width}px`} height={`${height}px`}></svg>
      </div> */}
      {downloadMsg && (
        <div style={{ position: 'absolute', top: '20px', right: '20px', width: 'fit-content', padding: '0', zIndex: '1' }}>
          <MessageBar messageBarType={MessageBarType.info}> {downloadMsg} </MessageBar>
        </div>
      )}
    </Dialog>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(CLTEDialog);
