import React, { useEffect, useState, useCallback, useRef, useMemo } from 'react';

import { connect } from 'react-redux';

import * as TextMapping from '../../utils/textMapping';
import PropertySelectionRow from './PropertySelectionRow';
import PropertySelectionGroup from './PropertySelectionGroup';

import { Pivot, PivotItem, Dropdown, SearchBox, Spinner, SpinnerSize } from '@fluentui/react';
import { niceRange } from '../processBuildingBlock';
import Plot from '../plot';
import { debounce, isEmpty, isUndefined } from 'lodash';
import { Margin5 } from '../../pages/styles';
import _ from 'lodash';

const mapStateToProps = (state) => {
  return {
    roots: state.roots,
  };
};

function ScatterPlot({ searchCatalog, texts, ranges, getScatterData, scatterData, qs, roots, scatterCBforRanges }) {
  const [selectionFields, setSelectionFields] = useState([]);
  const [selectionOne, setSelectionOne] = useState();
  const [selectionTwo, setSelectionTwo] = useState();
  const [collapsedVkeys, setCollapsedVkeys] = useState([]);
  const [plotData, setPlotData] = useState({});
  const fields = useRef([]);
  const [xrange, setxrange] = useState([]);
  const [yrange, setyrange] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [filter, setFilter] = useState('');
  const [groupOptions, setGroupOptions] = useState([]);
  const groups = useRef([]);
  const numberOfFieldsDisplayed = useRef(0);
  const [isSearchInitiated, setIsSearchInititated] = useState(false);
  const { filterData, appContent, language } = roots;
  const [isInitialized, setInitialized] = useState(false);
  const [selectedTab, setSelectedTab] = useState();
  const [firstRender, setFirstRender] = useState(true);
  const previousFilterData = useRef({});
  const previousQs = useRef({});
  const [selectionDebounced, setSelectionDebounced] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);
  const [vkey1, setVkey1] = useState();
  const [vkey2, setVkey2] = useState();

  const dropdownStyles = useMemo(() => {
    return {
      dropdown: {
        width: 300,
      },
      dropdownOptionText: {
        fontSize: '10px',
      },
    };
  }, []);

  const searchInitiated = useCallback(() => {
    setIsSearchInititated(true);
  }, []);

  const pivotStyles = {
    itemContainer: {
      height: 'calc(100% - 32px)',
      width: '100%',
      overflowY: 'hidden',
    },
  };

  const pivotItemStyles = {
    root: {
      height: '100%',
    },
  };

  const getKey = useCallback((child) => {
    return child.vkey + child.type;
  }, []);

  //debouncing frequent selections and filters
  const updateSelection = useCallback(
    debounce(() => {
      setSelectionDebounced((selectionDebounced) => !selectionDebounced);
    }, 1000),
    [selectionDebounced]
  );

  useEffect(() => {
    updateSelection();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectionOne, selectionTwo, filterData]);

  useEffect(() => {
    if (!isNaN(vkey1) && !isNaN(vkey2) && roots.hasMaterials && selectedTab === '.1') {
      setInitialized(false);
      getScatterData(vkey1, vkey2, qs.current);
      previousFilterData.current = filterData;
      previousQs.current = qs.current;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectionDebounced, getScatterData, language, qs.current, roots.hasMaterials, selectedTab, vkey1, vkey2]);

  useEffect(() => {
    setxrange([]);
    setyrange([]);
  }, [scatterData]);

  useEffect(() => {
    if (selectedTab === '.1' && firstRender) {
      setFirstRender(false);
    } else if (selectedTab !== '.1') {
      setInitialized(false);
      setFirstRender(true);
    }
  }, [selectedTab, firstRender]);

  const onRelayout = useCallback((event) => {
    if (
      !isUndefined(event['xaxis.range[0]']) &&
      !isUndefined(event['xaxis.range[1]']) &&
      !isUndefined(event['yaxis.range[0]']) &&
      !isUndefined(event['yaxis.range[1]'])
    ) {
      setxrange([event['xaxis.range[0]'], event['xaxis.range[1]']]);
      setyrange([event['yaxis.range[0]'], event['yaxis.range[1]']]);
    }
  }, []);

  const onDoubleClick = useCallback(() => {
    setxrange([]);
    setyrange([]);
  }, []);

  const onInitialized = useCallback(() => {
    //setInitialized(true);
  }, []);

  const onUpdate = useCallback(() => {
    setInitialized(true);
  }, []);

  const debouncedOnUpdate = useCallback(_.debounce(onUpdate, 300), [onUpdate]);

  const onLinkClick = useCallback((item) => {
    setSelectedTab(item.key);
  }, []);

  function onDropdownChange(event, item) {
    if (item) {
      setSelectedKeys(item.selected ? [...selectedKeys, item.key] : selectedKeys.filter((key) => key !== item.key));
    }
  }

  function onFilterChange(event, value) {
    setFilter(value);
  }

  const prepScatterData = useCallback(
    (scatterdata) => {
      if (scatterdata.data) {
        const numOfCurves = scatterdata.data.length;
        let xValues = [],
          yValues = [];
        let data = [];

        let uniqueTraceMap = {};

        uniqueTraceMap = {};

        for (let i = 0; i < numOfCurves; i++) {
          const x = parseFloat(scatterdata.data[i].x);
          const y = parseFloat(scatterdata.data[i].y);
          const key = x.toString() + '/' + y.toString();

          if (key in uniqueTraceMap) {
            if (uniqueTraceMap[key].names.length < 9) {
              uniqueTraceMap[key].names.push(scatterdata.data[i].name);
            } else if (uniqueTraceMap[key].names.length === 9) {
              uniqueTraceMap[key].names.push('...');
            } else {
              continue;
            }
          } else {
            uniqueTraceMap[key] = {
              point: { x: x, y: y },
              names: [scatterdata.data[i].name],
            };
          }
        }

        // prep trace
        let scaterrConfig = appContent.config && appContent.config.plotly && appContent.config.plotly.scatterplot;

        let trace = {
          x: [],
          y: [],
          name: '',
          mode: 'markers',
          type: 'scatter',
          customdata: [],
          marker: { color: scaterrConfig.color ?? '#00aaff' },
          hovertemplate:
            '<b>%{customdata.names}</b><br><br>' +
            scatterdata.y.name +
            ': %{y} ' +
            scatterdata.y.unit +
            '<br>' +
            scatterdata.x.name +
            ': %{x} ' +
            scatterdata.x.unit,
        };

        for (let key of Object.keys(uniqueTraceMap)) {
          trace.x.push(uniqueTraceMap[key].point.x);
          trace.y.push(uniqueTraceMap[key].point.y);
          trace.customdata.push({
            names: uniqueTraceMap[key].names.join('<br>'),
          });

          xValues.push(uniqueTraceMap[key].point.x);
          yValues.push(uniqueTraceMap[key].point.y);
        }

        data.push(trace);

        let niceRangeX = niceRange(!isEmpty(xrange) ? xrange : xValues, false);
        let niceRangeY = niceRange(!isEmpty(yrange) ? yrange : yValues, false);

        let layout = {
          title: TextMapping.getUIText(TextMapping.UI_TEXT_SCATTER_PLOT_TITLE, texts),
          width: 800,
          showlegend: false,
          hovermode: 'closest',
          hoverlabel: { bgcolor: '#FFF' },
          paper_bgcolor: 'rgba(0,0,0,0)',
          plot_bgcolor: 'rgba(0,0,0,0)',
          xaxis: {
            title: {
              text: `${scatterdata.x.name} [${scatterdata.x.unit}]`,
            },
            exponentformat: 'E',
            mirror: true,
            showline: true,
            range: niceRangeX.range,
            dtick: niceRangeX.dtick,
          },
          yaxis: {
            title: {
              text: `${scatterdata.y.name} [${scatterdata.y.unit}]`,
            },
            exponentformat: 'E',
            mirror: true,
            showline: true,
            range: niceRangeY.range,
            dtick: niceRangeY.dtick,
          },
        };

        return { data, layout, orientation: 'unconstrained' };
      }
    },
    [xrange, yrange, appContent, texts]
  );

  useEffect(() => {
    if (scatterData && scatterData.data) {
      let result = prepScatterData(scatterData);

      setPlotData(result);
    }
  }, [scatterData, prepScatterData, xrange, yrange]);

  useEffect(() => {
    let cbranges = new Map();

    if (xrange.length && yrange.length && scatterData.x && scatterData.y) {
      let niceRangeX = niceRange(xrange, false);
      let niceRangeY = niceRange(yrange, false);

      cbranges.set(Number(scatterData.x.vkey), { min: niceRangeX.range[0], max: niceRangeX.range[1] });
      cbranges.set(Number(scatterData.y.vkey), { min: niceRangeY.range[0], max: niceRangeY.range[1] });
    } else if (scatterData.x && scatterData.y && ranges && ranges.Spt && ranges.Spt[scatterData.x.vkey] && ranges.Spt[scatterData.y.vkey]) {
      let niceRangeX = niceRange(ranges.Spt[scatterData.x.vkey], false);
      let niceRangeY = niceRange(ranges.Spt[scatterData.y.vkey], false);

      cbranges.set(Number(scatterData.x.vkey), { min: niceRangeX.range[0], max: niceRangeX.range[1] });
      cbranges.set(Number(scatterData.y.vkey), { min: niceRangeY.range[0], max: niceRangeY.range[1] });
    }
    if (isSearchInitiated) {
      scatterCBforRanges(cbranges);
      setIsSearchInititated(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [yrange, isSearchInitiated, scatterData, xrange, scatterCBforRanges]);

  const onCheckbox1Change = useCallback((child, value) => {
    if (value === true) {
      setSelectionOne(getKey(child));
      setVkey1(child.vkey);
    } else {
      setSelectionOne('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCheckbox2Change = useCallback((child, value) => {
    if (value === true) {
      setSelectionTwo(getKey(child));
      setVkey2(child.vkey);
    } else {
      setSelectionTwo('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getComponentKey = useCallback((child, type) => {
    return child.type + '-' + child.vkey + '-' + type + 'property';
  }, []);

  const handleToggler = useCallback((child, value) => {
    if (value === true) {
      setCollapsedVkeys((oldCollapsedVkeys) => [...oldCollapsedVkeys, getKey(child)]);
    } else {
      setCollapsedVkeys((oldCollapsedVkeys) =>
        oldCollapsedVkeys.filter((collapsedVkey) => {
          return collapsedVkey !== getKey(child);
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const mapChildren = useCallback(
    (child, depth, fields, parentMatchedFilter, categorySelected, tableFields) => {
      let matchedFilter = false;

      let margin = depth * 10;
      let hasResults = false;

      if (
        child.type !== 'Group' &&
        child.vtype === 'numeric' &&
        !(child.catalog.specialvalueset && child.catalog.specialvalueset.specialonly)
      ) {
        if (
          ranges &&
          ranges.Spt &&
          ranges.Spt[child.vkey] &&
          ranges.Spt[child.vkey].length === 2 &&
          (ranges.Spt[child.vkey][0] || ranges.Spt[child.vkey][0] === 0) &&
          (ranges.Spt[child.vkey][1] || ranges.Spt[child.vkey][1] === 0)
        ) {
          if (filter === '' || child.name.toLowerCase().includes(filter.toLowerCase())) {
            matchedFilter = true;
          }

          fields.push(
            <PropertySelectionRow
              key={'property-selection-' + getKey(child)}
              selectionOne={selectionOne}
              selectionTwo={selectionTwo}
              child={child}
              onCheckbox1Change={onCheckbox1Change}
              onCheckbox2Change={onCheckbox2Change}
              getKey={getKey}
              margin={margin}
              shouldDisplay={parentMatchedFilter || matchedFilter}
            />
          );

          if (parentMatchedFilter || matchedFilter) {
            numberOfFieldsDisplayed.current++;
            tableFields.count++;
          }
        }
      } else if (child.type !== 'Feature' && child.children && child.children.length > 0) {
        if (filter !== '' && child.name.toLowerCase().includes(filter.toLowerCase())) {
          matchedFilter = true;
        }

        let newFields = [];

        if (depth === 0 && selectedKeys.includes(getKey(child))) {
          categorySelected = true;
        }

        let tableFields = { count: 0 };

        if (!collapsedVkeys.includes(getKey(child)) || filter !== '') {
          for (let newChild of child.children) {
            let result = mapChildren(newChild, depth + 1, newFields, filter !== '' && matchedFilter, categorySelected, tableFields);
            if (result) {
              hasResults = true;
            }
          }
        } else if (collapsedVkeys.includes(getKey(child))) {
          for (let newChild of child.children) {
            let tempNewFields = [];
            let tempTableFields = { count: 0 };
            let result = mapChildren(newChild, depth + 1, tempNewFields, filter !== '' && matchedFilter, categorySelected, tempTableFields);
            if (result) {
              hasResults = true;
            }
          }
        }

        if (hasResults && depth === 0) {
          groups.current.push({ key: getKey(child), text: child.name });
        }

        //const shouldDisplay = ((filter === '' || (filter !== '' && matchedFilter)) && (hasResults || collapsedVkeys.includes(getKey(child))));

        const shouldDisplay = (filter !== '' && matchedFilter) || hasResults;

        if (shouldDisplay && firstLoad && appContent && appContent.config.scatterplot && appContent.config.scatterplot.collapsed) {
          handleToggler(child, true);
        }

        if (selectedKeys.length === 0 || categorySelected) {
          fields.push(
            <PropertySelectionGroup
              shouldDisplay={shouldDisplay}
              key={getComponentKey(child, 'grouprow')}
              child={child}
              getKey={getKey}
              margin={margin}
              hasResults={hasResults}
              collapsedVkeys={collapsedVkeys}
              getComponentKey={getComponentKey}
              handleToggler={handleToggler}
            />
          );

          fields.push(
            <div
              className="bodyText"
              key={getKey(child)}
              style={{
                display: tableFields.count > 0 ? 'flex' : 'none',
                marginLeft: margin,
              }}
            >
              <div style={{ fontWeight: 'bold', fontSize: '10px', marginLeft: '13px' }}>X</div>
              <div style={{ fontWeight: 'bold', fontSize: '10px', marginLeft: '10px' }}>Y</div>
            </div>
          );
          if (hasResults || matchedFilter) {
            fields.push(newFields);
          }
        }
      }

      setGroupOptions(groups.current);

      return hasResults || matchedFilter || parentMatchedFilter;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      collapsedVkeys,
      getKey,
      onCheckbox1Change,
      onCheckbox2Change,
      selectionOne,
      selectionTwo,
      getComponentKey,
      handleToggler,
      ranges,
      filter,
      selectedKeys,
      appContent,
    ]
  );

  useEffect(() => {
    fields.current = [];
    groups.current = [];

    if (ranges && !isEmpty(ranges) && searchCatalog && searchCatalog.length > 0) {
      numberOfFieldsDisplayed.current = 0;
      for (let child of searchCatalog[0].children) {
        mapChildren(child, 0, fields.current, false, false, { count: 0 });
      }

      setFirstLoad(false);

      setSelectionFields(fields.current);
    }
  }, [searchCatalog, mapChildren, ranges, filter]);

  let opacity = roots?.rangeStatus === 'Loading' ? '0.5' : '1.0';

  return (
    <div
      className="amdc-block"
      style={{
        height: 'calc(100vh - 135px)',
        width: '100%',
        padding: '10px',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        opacity: opacity,
      }}
    >
      {roots?.rangeStatus !== 'SUCCESS' && (
        <div
          style={{
            zIndex: '10',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            background: 'rgba(255,255,255,.5)',
            position: 'absolute',
            width: '100%',
            height: '100%',
          }}
        >
          <Spinner size={SpinnerSize.large} />
        </div>
      )}
      <Pivot style={{ height: '100%' }} styles={pivotStyles} onLinkClick={onLinkClick}>
        <PivotItem
          itemKey="selection"
          headerText={TextMapping.getUIText(TextMapping.UI_TEXT_PROPERTY_SELECTION, texts)}
          data-testid="property-selection-tab"
        >
          <div style={{ paddingBottom: '10px' }}>
            <Margin5 />
            <Dropdown
              placeholder={TextMapping.getUIText(TextMapping.UI_TEXT_SELECT_CATEGORY, texts)}
              selectedKeys={selectedKeys}
              onChange={onDropdownChange}
              options={groupOptions}
              styles={dropdownStyles}
              multiSelect
            />
            <Margin5 />
            <SearchBox
              onChange={onFilterChange}
              value={filter}
              placeholder={TextMapping.getUIText(TextMapping.UI_TEXT_FILTER_BY_PROPERTY_NAME, texts)}
            />
          </div>
          <div
            data-testid="scatter-plot-scrollable-div"
            style={{ flex: 'auto', zIndex: '10', paddingTop: '10px', height: 'calc(100vh - 250px)', overflowY: 'auto' }}
          >
            {filter !== '' && numberOfFieldsDisplayed.current === 0
              ? TextMapping.getUIText(TextMapping.UI_TEXT_NO_FIELDS_MATCH_FILTER, texts)
              : ''}
            {selectionFields}
          </div>
        </PivotItem>
        <PivotItem
          itemKey="plot"
          className="scatter-plot-tab bodyText"
          styles={pivotItemStyles}
          headerText={TextMapping.getUIText(TextMapping.UI_TEXT_CHART, texts)}
          data-testid="scatter-plot-chart-tab"
        >
          {roots?.rangeStatus === 'SUCCESS' &&
            selectionOne &&
            selectionTwo &&
            !isInitialized &&
            !(scatterData.data && isEmpty(scatterData.data)) && (
              <div
                style={{
                  zIndex: '10',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  background: 'rgba(255,255,255,.5)',
                  position: 'absolute',
                  width: '100%',
                  height: '100%',
                }}
              >
                <Spinner size={SpinnerSize.large} />
              </div>
            )}
          {scatterData && scatterData.data && !firstRender && (
            <div style={{ display: 'flex', flex: 'auto', zIndex: '10', paddingTop: '10px', height: '100%' }}>
              <Plot
                name="scatterplot"
                plotData={plotData}
                onRelayout={onRelayout}
                onDoubleClick={onDoubleClick}
                onInitialized={onInitialized}
                onUpdate={debouncedOnUpdate}
                onSearchSelected={searchInitiated}
              />
            </div>
          )}
        </PivotItem>
      </Pivot>
    </div>
  );
}

export default connect(mapStateToProps)(ScatterPlot);
