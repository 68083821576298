import React, { useState, useEffect } from 'react';

import { Dropdown } from '@fluentui/react/lib/Dropdown';
import Plot from '../plot.js';
import { setupDiagram, createMultipleDiagram, onDoubleClick, onRelayout } from '../processBuildingBlock.js';
import * as TextMapping from '../../utils/textMapping';

function PlotComparison({ catalogs, selectedEntities, plotResize, plotFullScreen, texts, setDiagramsAvailable }) {
  const [selectedItem, setSelectedItem] = useState();
  const [optionArray, setOptionArray] = useState([]);
  const [savedEntityMap, setSavedEntityMap] = useState();
  const [layoutMap, setLayoutMap] = useState(new Map());

  useEffect(() => {
    let selectedEntityMap = new Map();
    let propertyNameMap = new Map();
    let options = [];

    for (let selectedEntity of selectedEntities) {
      let diagramMap = new Map();

      if (selectedEntities && selectedEntities.length > 0) {
        diagramMap = mapDiagrams(selectedEntity);

        selectedEntityMap.set(selectedEntity._key, diagramMap);

        for (let [key, diagramArray] of diagramMap.entries()) {
          if (diagramArray && diagramArray.length > 0) {
            for (let diagram of diagramArray) {
              // look for diagram for diagram model to prevent duplicates
              let foundDiagram = false;

              if (diagram.type === 'diamodel') {
                let resultArray = diagramMap.get('dia/' + diagram.vkey);

                if (resultArray) {
                  for (let result of resultArray) {
                    if (result.moisturestate === diagram.moisturestate && result.variant === diagram.variant && result.plotData) {
                      foundDiagram = true;
                      break;
                    }
                  }
                }
              }
              if (!foundDiagram) {
                propertyNameMap.set(key + '|' + diagram.variant, diagram.catalogname);
              }
            }
          }
        }
      }
    }

    for (const [key, value] of propertyNameMap) {
      options.push({ key: key, text: value });
    }

    setOptionArray(options);
    setSavedEntityMap(selectedEntityMap);
    if (options.length > 0) {
      setDiagramsAvailable(true);
    } else {
      setDiagramsAvailable(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedEntities, catalogs, layoutMap]);

  function onPlotChange(e, value) {
    setSelectedItem(value);
  }

  function mapDiagrams(plots) {
    let diagramMap = new Map();

    if (plots && plots.dia && plots.dia.length > 0) {
      for (let entry of plots.dia) {
        let diagram = JSON.parse(JSON.stringify(entry.value[0]));
        let catalog = JSON.parse(JSON.stringify(entry.catalog));
        catalog._key = catalog._key + '|' + plots._id;

        diagram.type = 'dia';
        diagram.catalogname = catalog.name;
        diagram.materialName = plots.name;
        diagram.catalogKey = catalog._key;
        diagram.catalogOptions = catalog.options;

        const mapKey = diagram.type + '/' + diagram.vkey;

        if (Array.isArray(catalog.axes.y)) {
          diagram.plotData = createMultipleDiagram(catalog, diagram, layoutMap);
        } else {
          diagram.plotData = setupDiagram(catalog, diagram, layoutMap);
        }

        if (diagram.vkey) {
          if (!diagramMap.has(mapKey)) {
            diagramMap.set(mapKey, [diagram]);
          } else {
            let diagramArray = diagramMap.get(mapKey);
            diagramArray.push(diagram);
          }
        }
      }
    }

    return diagramMap;
  }

  let plotComponents = [];

  if (savedEntityMap && selectedItem) {
    for (const value of savedEntityMap.values()) {
      let keyVariantPair = selectedItem.key.split('|');
      let diagramArray = value.get(keyVariantPair[0]);

      if (diagramArray && diagramArray.length > 0) {
        for (let diagram of diagramArray) {
          if (diagram.variant?.toString() === keyVariantPair[1]) {
            let plot = diagram.plotData;
            let name = diagram.materialName;

            let parameters = [];
            let parameterValues = diagram.parameter;
            if (parameterValues) {
              for (let [key, value] of Object.entries(parameterValues)) {
                parameters.push(
                  <div>
                    {key}: {value}
                  </div>
                );
              }
            }

            if (plot) {
              plot.layout.title.text = diagram.name + '<br>' + name;
              plotComponents.push(
                <div style={{ display: 'flex', flexDirection: 'column', minWidth: '270px', flex: '1 1' }}>
                  <Plot
                    plotResize={plotResize}
                    name={'comparechart'}
                    fullScreen={plotFullScreen}
                    plotData={plot}
                    onRelayout={(event) => {
                      onRelayout(
                        diagram.catalogKey,
                        event,
                        diagram.catalogOptions.scale.x === 'log',
                        diagram.catalogOptions.scale.y === 'log',
                        layoutMap,
                        setLayoutMap
                      );
                    }}
                    onDoubleClick={() => onDoubleClick(diagram.catalogKey, layoutMap, setLayoutMap)}
                  />
                </div>
              );
            } else {
              plotComponents.push(
                <div
                  style={{
                    minWidth: '270px',
                    fontFamily: 'Noto Sans',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: '10px',
                  }}
                >
                  {TextMapping.getUIText(TextMapping.UI_TEXT_NO_PLOT_FOUND_FOR, texts)} {name}
                </div>
              );
            }
          }
        }
      }
    }
  }

  return (
    <div style={{ padding: '10px', display: 'flex', flexDirection: 'column', height: '100%' }}>
      {optionArray.length > 0 && (
        <Dropdown
          placeholder={TextMapping.getUIText(TextMapping.UI_TEXT_SELECT_A_PLOT, texts)}
          options={optionArray}
          required={true}
          defaultSelectedKey={selectedItem && selectedItem.key}
          onChange={onPlotChange}
          selectedKey={selectedItem ? selectedItem.key : undefined}
        />
      )}
      {optionArray.length === 0 && <div>{TextMapping.getUIText(TextMapping.UI_TEXT_NO_DIAGRAMS_AVAILABLE, texts)}</div>}

      <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(270px, 1fr))', gap: '16px', flexGrow: '1' }}>
        {plotComponents}
      </div>
    </div>
  );
}

export default PlotComparison;
